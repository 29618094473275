nav {
    font-size: 1.6em;
    background: rgba(56, 195, 254, 0.1);
}
nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex ;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row ;
    flex-direction: row ;
    -ms-flex-wrap: wrap ;
    flex-wrap: wrap ;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center ;
    -ms-flex-align: center ;
    align-items: center ;
    -ms-flex-line-pack: center ;
    align-content: center ;
}
nav li {
    list-style: none;
    margin: 5px 0px 5px 0px;
}
nav a {
    border-radius: 20px;
    margin-right: 5px;
    display: block;
    color: white;
    padding: 10px;
    text-decoration: none;
}
nav a:hover, .active {
    background-color: rgba(154, 238, 255, 0.3);
}
.logout {
    width: 35px;
    cursor: pointer;
}
#hamburger
{
    display: none;
}

@media (max-width: 1000px)
{
    nav ul {
        display: flex;
        flex-direction: column;
    }
    li {
        display: none;
        width: 100%;
        text-align: center;
    }
    #hamburger {
        display: block;
    }
}