* {
  margin:0px;
  padding:0px;
}

body {
  font-size: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("./assets/images/background.jpg");
  font-family: 'Cantarell';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
}
.add-button {
  display: none;
}

@media (max-width: 1000px)
{
  .add-button {
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    padding: 10px 19px;
    background: #2A2A2A;
  }
  .mobile {
    display: block;
  }
}