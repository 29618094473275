.trackingView {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.trackingBloc {
    margin: 15px;
    padding: 5px;
    background-color: #F8F8F8;
    border-color: #a8a8a8;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(66,66,66,0.75);
    white-space: pre-wrap;
}
.selectGroup {
    width: 100%;
}
.content {
    max-width: 1000px;
}
.icon {
    width: 25px;
    vertical-align: text-top;
}
.leftBar {
    border-left: 2px solid rgba(168,168,168,0.50);
}
.delete {
    text-align: right;
}

@media (max-width: 1000px)
{
    .trackingView {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}