fieldset {
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px;
}
button {
    font-size: 1.1em;
    margin: 5px;
    cursor: pointer;
    color: #FFFFFF;
    padding: 10px 19px;
    border-radius: 15px;
    border: 0px;
    background: linear-gradient(to top, #38C3FE, #468CCF);
}
button:hover {
    color: #FFFFFF;
    background: linear-gradient(to top, #9AEEFF, #38C3FE);
}
table {
    border-collapse: collapse;
    border-radius: 20px 0px 0px 0px;
    margin-bottom: 20px;
    width: 100%;
}
table th {
    font-size: 1.2em;
    color: white;
    background-color: rgba(56, 195, 254, 1);
    border-radius: 20px 20px 0px 0px;
}
table tbody {
    box-shadow: 0px 0px 8px #00F3FF;
}
table td {
    padding: 10px 10px 10px 10px;
}
.tdGroups {
    padding: 3px 3px 3px 3px;
}
.tdMin {
    width: 55px;
}
table .backTr {
    background-color: rgba(56, 195, 254, 0.2);
}
.deleteColor {
    font-size: 1.1em;
    margin: 5px;
    cursor: pointer;
    color: #FFFFFF;
    padding: 10px 19px;
    border-radius: 15px;
    border: 0px;
    background: linear-gradient(to top, #FE3838, #CF4646);
}
.deleteColor:hover {
    color: #FFFFFF;
    background: linear-gradient(to top, #FF6060, #FE3838);
}
h1 {
    display: block;
    text-align: center;
    margin-bottom: 50px;
}
h2, h3 {
    display: inline;
}
a {
    color: rgba(66, 188, 255, 1);
}
form {
    width: 450px;
    text-align: left;
}
.page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    max-width: 1500px;
    margin: 10px auto;
    box-shadow: 0px 0px 8px #00F3FF;
    background: white;
    padding: 10px;
}
.page-log {
    max-width: 95%;
}
.delete-log {
    cursor: pointer;
}
.news {
    width: 60%;
    border: solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 30px;
}
.small-text {
    font-size: 0.8em;
}
.field {
    padding: 3px;
    font-size: 16px;
    border-width: 3px;
    border-color: #a8a8a8;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(66,66,66,.75);
    min-width: 97%;
    max-width: 97%;
}
select {
    padding: 3px;
    border-width: 3px;
    border-color: #a8a8a8;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(66,66,66,.75);
}
.checkbox {
    width: 20px;
    box-shadow: none;
}
.center {
    text-align: center;
}
.minWidth {
    width: 500px;
}
.maxWidth {
    width: 100%;
}
.infoPhone {
    font-size: 0.9em;
    font-style: italic;
}
.obligatory {
    color: red;
}
.error {
    color: red;
}
.valide {
    color: green;
}
.imgEdit {
    cursor: pointer;
}
.reduceSize {
    font-size: 0.9em;
}

@media (max-width: 1000px)
{
    .pageContent {
        width: 90%;
    }
    .minWidth {
        width: 90%;
    }
    .field {
        padding: 3px;
        width: 97%;
    }
    form {
        width: 100%;
    }
}